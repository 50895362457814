/* global headerSectionComponent */
class HeaderSectionComponent extends headerSectionComponent {

    data() {
        const globalData = super.data();
        globalData.reloadingOptions = false;
        return globalData;
    }

    getMethods() {
        let superMethods = super.getMethods();
        superMethods.openSearchInput = this.openSearchInput;
        superMethods.closeSearchInput = this.closeSearchInput;
        return superMethods;
    }

    openSearchInput() {
        if (!this.isMobile) {
            $(".search-event-icon").hide();
        }
        $("#search-wrapper").show();
        setTimeout(() => {
            this.$refs.search.focus();
        }, 500);
    }
        
    closeSearchInput() {
        this.filterValue = "";
        if (!this.isMobile) {
            $(".search-event-icon").show();
        }
        $("#search-wrapper").hide();
    }

    getTemplate() {
        return `<div>
                    <div class="top-bar text-center text-uppercase p-2">
                        <p>LOS PRECIOS PUBLICADOS INCLUYEN IVA. ELEGI MEDIO DE PAGO Y FORMA DE ENVIO. LLEGAMOS A TODO EL PAIS</p>
                    </div>
                    <nav class="navbar navbar-expand-lg navbar-light" v-if="mustRender">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"><i class="icon fas fa-bars"></i></span>
                        </button>

                        <router-link to="/home" class="nav-link brand-link" href="#">
                            <template v-if="hasBrandImg">
                                <img class="brand-logo"  :src="logoUrl">
                                <p class="brand-text text-right">Mayoristas</p>
                            </template>
                            <template v-if="!hasBrandImg">
                                <span class="brand-logo">{{menuTitle}}</span>
                                <span class="app-name">{{menuSubTitle}}</span>
                            </template>
                        </router-link>

                        <ul class="icons-nav navbar-nav flex-row ml-auto d-flex justify-content-end order-lg-2">
                            <li class="nav-item search-wrapper-container" :title="tr('Search')">
                                <span class="nav-link search-event-icon" @click="openSearchInput">
                                    <i class="fas fa-search"></i>
                                </span>
                                <div id="search-wrapper" class="search-wrapper form-inline" autocomplete="off" @click="rapidMenu=true" >
                                    <input list="searchinput" ref="search" class="inputSEARCH search-input form-control mr-sm-2" type="search" v-model="filterValue" @change2="filter" @keydown="delayReload" @keydown.enter="filter" aria-label="Search" autocomplete="none" :placeholder="tr('ECOMSEARCHTEXT')">
                                    <span class="search-icon" @click="filter"><i class="fas fa-search"></i></span>
                                    <span class="search-close-icon" @click="closeSearchInput"><i class="fas fa-times"></i></span>
                                    <div class="header_datalist container pl-1 pl-sm-3 pr-1 pt-3" v-if="showRapidMenu" @mouseleave="rapidMenu=false">
                                        <div class="categorias d-none" >
                                        </div>
                                        <!--h6 class="font-weight-bold my-3">Productos Sugeridos</h6-->
                                        <div class="products-scroll products-scroll--alt" >
                                            <template v-for="opt in optionList">
                                                <div class="product-small product-small--alt py-2 mr-1">
                                                    <div class="row">
                                                        <div class="product__image col-3 col-md-2 px-2">
                                                            <div class="item-detail-modal" >
                                                                <itemSliderComponent :key="'slide'+opt.Code" :item="opt" :stockFlag="$store.getters.showStock" :container="false"  :zoom="false"></itemSliderComponent>
                                                            </div>
                                                        </div>
                                                        <div class="product__content col-9 col-md-10 px-2 align-self-center">
                                                            <h6 class="col-12 pl-1">
                                                                <router-link :to="'/view/'+encodeToURL(opt.Code)">{{opt.Name}}</router-link>
                                                            </h6>
                                                            <div class="col-12 pl-1">
                                                                <div class="row align-items-center">
                                                                    <div class="price col-12 col-sm-4 col-md-4"  v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                        <strong class="d-block crossedoutprice" v-if="opt.CrossedOutPrice">
                                                                            $ {{opt.CrossedOutPrice}}
                                                                        </strong>
                                                                        $ {{$store.getters.showItemPrice(opt)}}
                                                                    </div>
                                                                    <div class="col-12 col-md-8 pl-0">
                                                                        <div class="input-group add-to-cart-buttons">
                                                                            <div class="input-group-prepend" @click="qtyDecrement(opt)">
                                                                                <span class="input-group-text button" id="validationTooltipUsernamePrepend">
                                                                                    <i class="icon fas fa-minus"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input :key="'th-input-'+opt.Code" type="text" class="form-control" :value="opt.carQty" :max="maxItemInCar(opt)" :disabled="maxItemInCar(opt)<1" @change="(event)=>updateCtyValue(event,opt)" @onfocus="autoResetBlur"  @focusout="(event)=>updateCtyValue(event,opt)" >
                                                                            <div class="input-group-append" @click="qtyIncrement(opt)">
                                                                                <span class="input-group-text button" id="validationTooltipUsernamePrepend">
                                                                                    <i class="icon fas fa-plus"></i>
                                                                                </span>
                                                                            </div>
                                                                            <div class="input-group-append" @click.stop="addToCar(opt)">
                                                                                <span class="cart-button input-group-text button ml-2 p-2" id="validationTooltipUsernamePrepend">
                                                                                    <i class="icon fas fa-cart-plus"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="col-12 text-center mt-2 mb-2" v-if="filterValue">
                                            <div @click="filter" class="btn-link" >Ver todos los productos</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item">
                                <template  v-if="session">
                                    <router-link key="profile-link" to="/profile" :title="tr('Profile')" class="nav-link">
                                    <img key='avatar ' :src="avatarUrl" alt="" width="22" height="22" class="avatar img-fluid customer-image responsive-img">
                                    </router-link>
                                </template>
                                <template v-else>
                                    <router-link key="login-link" to="/login" :title="tr('Login')" class="nav-link">
                                        <i class="fa fa-user" ></i>
                                    </router-link>
                                </template>
                            </li>
                            <li class="nav-item cart-icon-container" :title="tr(['Total Amount in Car $ {{$1}}', carAmount])">
                                <router-link to="/badges" class="nav-link cart-icon">
                                    <i class="fa fa-shopping-cart" ></i>
                                    <span class="cart-badge badge badge-light">
                                        {{metricNumber(parseInt(carItemBadge))}}
                                    </span>
                                </router-link>
                                <span class="total" v-if="$store.getters.getSetting.ShowTotalPrice">$ {{carAmount}}</span>
                            </li>
                            <li class="nav-item" v-if="session" :title="tr('Favorities')">
                                <router-link to="/favorities" class="nav-link">
                                    <i class="custom-icon favourite-icon"></i>
                                </router-link>
                            </li>
                        </ul>

                        <div class="collapse navbar-collapse justify-content-end w-100 tukson-subheader" id="navbarSupportedContent">
                            <subheaderSectionComponent class="sub-header w-100"></subheaderSectionComponent>
                        </div>
                    </nav>
                </div>`;
    }
}

HeaderSectionComponent.registerComponent();
